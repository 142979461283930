import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
export const _frontmatter = {
  "title": "Set up an FTP Server for your WQHD Camera using Docker",
  "path": "/Frequently_Asked_Question/FTP_Server_for_your_WQHD_Camera_using_Docker/",
  "dateChanged": "2022-05-23",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).",
  "image": "../../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='FAQs - Set up an FTP Server for your WQHD Camera using Docker' dateChanged='2022-05-23' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/FTP_Server_for_your_WQHD_Camera_using_Docker/' locationFR='/fr/Frequently_Asked_Question/FTP_Server_for_your_WQHD_Camera_using_Docker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "set-up-an-ftp-server-for-your-wqhd-camera-using-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#set-up-an-ftp-server-for-your-wqhd-camera-using-docker",
        "aria-label": "set up an ftp server for your wqhd camera using docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up an FTP Server for your WQHD Camera using Docker`}</h1>
    <p><strong parentName="p">{`Update`}</strong>{`: This tutorial only works with `}<strong parentName="p">{`Active`}</strong>{` (`}<strong parentName="p">{`PORT Mode`}</strong>{`) uploads. For `}<strong parentName="p">{`Passive`}</strong>{` (`}<strong parentName="p">{`PASV`}</strong>{`) mode please read our tutorial `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Passive_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`Set up a Passive FTP Server for your WQHD Camera using Docker`}</a></p>
    <p><strong parentName="p">{`Update`}</strong>{`: Extended tutorial to `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/FTP_Secure_Server_for_your_WQHD_Camera_using_Docker/"
      }}>{`Set up a Secure FTP Server for your WQHD Camera using Docker`}</a>{` by adding TLS encryption.`}</p>
    <p><strong parentName="p">{`Q`}</strong>{`: I want to use my cameras FTP service to upload alarm recordings and snapshot series to my Linux Server (Raspberry Pi).`}</p>
    <p><strong parentName="p">{`A`}</strong>{`: The simplest way would be to use your `}<a parentName="p" {...{
        "href": "/en/Motion_Detection/Router_as_a_FTP_Server/"
      }}>{`Internet Router as FTP Server`}</a>{`. If you want to use a Linux Server like an Raspberry Pi we recommend using the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/Using_the_sFTP_Service_with_your_WQHD_Camera/"
      }}>{`sFTP Service`}</a>{` instead of FTP or FTPS. But of course you can also set up your own FTP server instead. I would recommend building an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` Docker image for this task.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#preparing-the-docker-image"
        }}>{`Preparing the Docker Image`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-login"
            }}>{`FTP User Login`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#ftp-user-configuration"
            }}>{`FTP User Configuration`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#server-configuration"
            }}>{`Server Configuration`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#starting-the-docker-container"
        }}>{`Starting the Docker Container`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#build-the-image"
            }}>{`Build the Image`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#export-the-image-optional"
            }}>{`Export the Image Optional`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#run-the-container"
            }}>{`Run the Container`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#verify-that-the-server-is-operational"
            }}>{`Verify that the Server is Operational`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#connecting-an-instar-ip-camera"
        }}>{`Connecting an INSTAR IP Camera`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#full-hd-cameras"
            }}>{`Full HD Cameras`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#wqhd-cameras"
            }}>{`WQHD Cameras`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "preparing-the-docker-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#preparing-the-docker-image",
        "aria-label": "preparing the docker image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Preparing the Docker Image`}</h2>
    <p>{`Start by creating a work directory and your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`, e.g. :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /opt/vsftpd
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`nano`}</span>{` Dockerfile`}</code></pre></div>
    <p>{`And add the following content:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Dockerfile for vsftpd on CentOS7`}</span>{`
FROM centos`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`7`}</span>{`

MAINTAINER m.polinowski@instar.com

RUN yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y update; yum `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`y install which vsftpd net`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`tools vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`sysvinit; yum clean all

COPY vusers.txt /etc/vsftpd/
RUN db_load `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`T `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`t hash `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`f /etc/vsftpd/vusers.txt /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db; rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`v /etc/vsftpd/vusers.txt; \\
	chmod 600 /etc/vsftpd/vsftpd`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`virtual`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`user.db
COPY vsftpd.conf /etc/vsftpd/
COPY vsftpd.virtual /etc/pam.d/
RUN mkdir `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`p /home/vftp/ftpuser; chown `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{`R ftp`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`ftp /home/vftp

EXPOSE 20 21

CMD `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"/usr/sbin/vsftpd"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"-obackground=NO"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`This `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{` will take the CentOS as a base image - this can be replaced by any flavour of Enterprise Linux. The next step installs the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{` service and creates the FTP User account for us. After that we need to copy the following configuration files into the image - all of the need to be created in the same directory where we placed our `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Dockerfile`}</code>{`:`}</p>
    <h3 {...{
      "id": "ftp-user-login",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-login",
        "aria-label": "ftp user login permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Login`}</h3>
    <p><em parentName="p">{`vusers.txt`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`ftpuser
mypassword`}</code></pre></div>
    <p>{`This is the user we will have to use to connect to the FTP server - change both the username and password according to your needs.`}</p>
    <h3 {...{
      "id": "ftp-user-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#ftp-user-configuration",
        "aria-label": "ftp user configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`FTP User Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.virtual`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`#%PAM-1.0`}</span>{`
auth       required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
account    required     pam_userdb.so `}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`db`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/etc/vsftpd/vsftpd-virtual-user
session    required     pam_loginuid.so`}</code></pre></div>
    <h3 {...{
      "id": "server-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#server-configuration",
        "aria-label": "server configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Configuration`}</h3>
    <p><em parentName="p">{`vsftpd.conf`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`anonymous_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`NO
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`virtual_use_local_privs`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`write_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_umask`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`022
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`pam_service_name`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`vsftpd.virtual
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`guest_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`user_sub_token`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`local_root`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/home/vftp/`}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`$USER`}</span>{`
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`chroot_local_user`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`allow_writeable_chroot`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`hide_ids`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_enable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`YES
`}<span parentName="code" {...{
            "className": "token assign-left variable"
          }}>{`xferlog_file`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`/var/log/vsftpd.log`}</code></pre></div>
    <p>{`Let's start with a very basic configuration.`}</p>
    <h2 {...{
      "id": "starting-the-docker-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#starting-the-docker-container",
        "aria-label": "starting the docker container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Starting the Docker Container`}</h2>
    <h3 {...{
      "id": "build-the-image",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#build-the-image",
        "aria-label": "build the image permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Build the Image`}</h3>
    <p>{`With those 4 files in place we are now ready to build our Docker image:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker build -t vsftpd `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`.`}</span></code></pre></div>
    <h3 {...{
      "id": "export-the-image-optional",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#export-the-image-optional",
        "aria-label": "export the image optional permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Export the Image (Optional)`}</h3>
    <p>{`This will have build our image and named it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd`}</code>{`. We can export this image and place it where we need it - `}<strong parentName="p">{`skip this step`}</strong>{` if you are already on the system you want to use for your server:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker save -o vsftpd.docker vsftpd:latest`}</code></pre></div>
    <p>{`Copy the exported `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd.docker`}</code>{` file to your offline PC and import it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker load -i vsftpd.docker`}</code></pre></div>
    <h3 {...{
      "id": "run-the-container",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#run-the-container",
        "aria-label": "run the container permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Run the Container`}</h3>
    <p>{`Make sure that the FTP user directory exists and can be written to by your Docker user:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` -p /opt/vsftpd/ftpuser
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`chmod`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`755`}</span>{` -R /opt/vsftpd/*`}</code></pre></div>
    <blockquote>
      <p parentName="blockquote">{`Make sure that the home directory `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`/opt/vsftpd/ftpuser`}</code>{` is set to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`777`}</code>{` to prevent the FTP write error `}<strong parentName="p">{`ftp 550 Create directory operation failed.`}</strong>{` or `}<strong parentName="p">{`553 Could not create file.`}</strong></p>
    </blockquote>
    <p>{`And run the container with - `}<strong parentName="p">{`note`}</strong>{` if you build the image locally it will be called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`vsftpd:latest`}</code>{`. If you imported it it will be called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`localhost/vsftpd:latest`}</code>{` and you have to change the name below:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -d `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --name vsftpd `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --net`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{`host `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --privileged `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    --rm `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    -v /opt/vsftpd/ftpuser/:/home/vftp/ftpuser/ `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
    vsftpd:latest`}</code></pre></div>
    <h3 {...{
      "id": "verify-that-the-server-is-operational",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#verify-that-the-server-is-operational",
        "aria-label": "verify that the server is operational permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Verify that the Server is Operational`}</h3>
    <p>{`We can now verify that our server is active for example with the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lftp`}</code>{` client (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`apt install lftp`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`lftp -d -u ftpuser `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111
Password: mypassword
---- Resolving `}<span parentName="code" {...{
            "className": "token function"
          }}>{`host`}</span>{` address`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`..`}</span>{`.
---- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` address found: `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111`}</code></pre></div>
    <p>{`Use your FTP servers IP address, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.111`}</code>{`, and your FTP login, e.g. `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ftpuser`}</code>{`/`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mypassword`}</code>{`, to login. Use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ls`}</code>{` command to see details about your connection:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`lftp ftpuser@192.168.2.111:~`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ls`}</span>{`
---- Connecting to `}<span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` port `}<span parentName="code" {...{
            "className": "token number"
          }}>{`21`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`220`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`vsFTPd `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3.0`}</span>{`.2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` FEAT
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`211`}</span>{`-Features:
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  EPRT
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  EPSV
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  MDTM
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  PASV
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  REST STREAM
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  SIZE
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  TVFS
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`---  UTF8
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`211`}</span>{` End
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` AUTH TLS
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`530`}</span>{` Please login with `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`USER`}</span>{` and PASS.
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` OPTS UTF8 ON
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span>{` Always `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{` UTF8 mode.
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`USER`}</span>{` ftpuser
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`331`}</span>{` Please specify the password.
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` PASS XXXX
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`230`}</span>{` Login successful.
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token environment constant"
          }}>{`PWD`}</span>{`
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`257`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"/"`}</span>{`
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` PASV
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`227`}</span>{` Entering Passive Mode `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192,168`}</span>{`,2,111,47,12`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`.
---- Connecting data socket to `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`192.168`}</span>{`.2.111`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` port `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12044`}</span>{`
---- Data connection established
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` LIST
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`150`}</span>{` Here comes the directory listing.
---- Got EOF on data connection
---- Closing data socket
-rwxr-xr-x    `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ftp`}</span>{`      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`ftp`}</span>{`        `}<span parentName="code" {...{
            "className": "token number"
          }}>{`194654`}</span>{` Dec `}<span parentName="code" {...{
            "className": "token number"
          }}>{`29`}</span>{` 07:16 T21122908162800.jpg
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`226`}</span>{` Directory send OK.
---- Closing idle connection
---`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` QUIT
`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`--- `}<span parentName="code" {...{
            "className": "token number"
          }}>{`221`}</span>{` Goodbye.
---- Closing control socket`}</code></pre></div>
    <p>{`Or in a more graphical way use Filezilla:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/c23ad/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB1klEQVQoz02Q2Y6jMBBF+YIQ0hK2sQEDCZjFC15YQraZ7s5I3f//PzMk6mik81TSqbq3vIxmaZbTPCdxQkicUhonCSYEQIhQBFEUAhACGAIA4Dp6EEWYYBJ7Nzf3ZtR6aKRpeC+049rx3kgzCO1aqbmyXBmubN2pA+uqRpR1R0i8yloJthyJGWjBaMHSokrzlbxq9Xg6/vpzuX+f71+nzy+93Fo78+lcCh0Eu93uzUNRnI4jsjYEGPwQhhHCCddjI600U6tcI9Sh5kwY4eaiaoNgFwQ7D8dZWZQJSlCUQBQ/AZBEhAo72fmy3O7T+WO5vo/Lzc638fSb90MQ7ABAXkQoSXMcZ+jHfMqYUGnnrh+qRtC8ygqW7+sHDc0r39/6/naVASTwP/N1ueuHWpiy5gfW7au2eLBnXbZnm42/2fgewimAZOXVGZIQYIRTPZ30dLLHaz+dG+Xafmj7odNj2cggeFsfdmhkwXjV9coduR71sNRCF4yXrcr2DMdZRGhEKI6zFwndkySN09SrpWXCSHe8fX5Pl/frx5d0RyZMoxxJcgDwqwiAGED8CJX4/naNXUtbS9soJ8zU6VHaue3dc1hLw8RK9djeD4u0s5nOrbKbjf/vYX8B3pthnEwH1DQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 230w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 460w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 920w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/73333/FTP_Server_for_your_WQHD_Camera_using_Docker_01.avif 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 230w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 460w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 920w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/1d5af/FTP_Server_for_your_WQHD_Camera_using_Docker_01.webp 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 230w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 460w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 920w", "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/c23ad/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png 1193w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cd6b2527c5b78d2e77b9668ccbabfdfd/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_01.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Make sure that you use the `}<strong parentName="p">{`Active`}</strong>{` aka `}<strong parentName="p">{`Port`}</strong>{` mode here:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6d3c7f6260102f9020c5d25d566db0f2/393aa/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.52173913043479%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjElEQVQoz3XQ626bMBiAYa4AGlLFRwyBhWCo8RHsJiRb1007aPd/PRvQppW2Se+PT7Ye+RDtiyIvy6KqaMZolhVFkTFGKYUQQYQRwgBACBGAECwra5hQQrPo03jSLlgzttp10kjrpR177ZT1dnwU2rXKcqHnodd1Kxqhjq0gNJuxVuJ4OhHj8vK4VLP9XFm31+fvn7/9uoZJD6dhetJ+4nLojD90arNJN5s0Qpjl3iPrACBv7TBEjMtBWj/oUbjApTsK0/SWS/eBixdMWdnUbU4LhBlE2UuQYlI8aG/CRfmzCZemU3WnbjhNtzsAI0wLwvaY5m8SZQBSTIv1qLXbvOIkuUuSuwiTHED6Xv4T31pxHCdxnMxvBpAukaV53gGCSf5/3Kfb++32Pjq0suKiEcaMU2+DDRfe24qLQyf/lis+tD3JWMbyiEvX9FaO549ff/rL8/XLDzmcm962ali3XjNHYda7VM3rtbl0XLpWDb0ND8ZL99gtbF1c49JpP9lw1eNkpqfOhThO/nzYb1j4ZKH6eSR9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d3c7f6260102f9020c5d25d566db0f2/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 230w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 460w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 920w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/17ac9/FTP_Server_for_your_WQHD_Camera_using_Docker_02.avif 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6d3c7f6260102f9020c5d25d566db0f2/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 230w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 460w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 920w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/b6585/FTP_Server_for_your_WQHD_Camera_using_Docker_02.webp 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6d3c7f6260102f9020c5d25d566db0f2/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 230w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 460w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 920w", "/en/static/6d3c7f6260102f9020c5d25d566db0f2/393aa/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png 1190w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6d3c7f6260102f9020c5d25d566db0f2/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_02.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Try to connect and create a folder inside the home director. If you see `}<strong parentName="p">{`ftp 550 Create directory operation failed.`}</strong>{` you have to re-run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`chmod 755 /opt/vsftpd/ftpuser`}</code>{` to make sure that you have write permissions.`}</p>
    <h2 {...{
      "id": "connecting-an-instar-ip-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#connecting-an-instar-ip-camera",
        "aria-label": "connecting an instar ip camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting an INSTAR IP Camera`}</h2>
    <p>{`Make sure to set the `}<strong parentName="p">{`Mode to PORT`}</strong>{`:`}</p>
    <h3 {...{
      "id": "full-hd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#full-hd-cameras",
        "aria-label": "full hd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Full HD Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/2e367/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAB9ElEQVQoz12QW2vTYACGs/RAm55s61TSE7Vrv5mkoxcm5vClpzVfi0kPyZdjW2VU8cJr9UJwWycIw1tBwb8g3Y0X/jzpOubw4b19eOAlGIZhWe4JOHg6cHuvv8rLtbS8EBf/T335ubf60hS6o6FWr9dpmq7VagTLshzXBPXHfeftyeWf+cWVv75yz2/mnG2cs419upmvN6vL30cSOuIYmi7k8/lisUgw1wAAFEnwLMM00EzXTAOZBrLGQ13rdBW+q/Ay39I6crVcioQjyUQyFo1l0pldmQMAqKoazBfYdiyMd8O2bRiGAqEkK6IoITSs1Q6SiWQ6naEoKpvNEhzHsSwLAIAQ+r5v3QFjrOt6u92GEMqyjBASeGH//n48FqfiVC6X25YZhmk0GhDCIAg8z8MY35XhNZIkaUhrtVoJKkGS5N4emcnc28q35cU88F0b/yvbujHudLsqhIooIk1jGTaVTBXoQoEu0DR9I+/KE+wPJ44+tSxzi2VOjdEx6qsdNHh23B/oeqVSiUai28NisXwufyuDbhv+eDf99cn4+MacYde1LWf5Kvjw3X3/7dR+8XM8O7ecUrUaIkmCIFKp1LbcbDZZlgGHh4LAn8x6q7Gk9yVJVmRJVNQeHHnyyHkuKh4vTGT44OGjUCgUDofLpXKlXPkL6E6PfZoSTHwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce18cb96d502a60eb85b045ab29a7ca/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 230w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 460w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 920w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/730d0/FTP_Server_for_your_WQHD_Camera_using_Docker_03.avif 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5ce18cb96d502a60eb85b045ab29a7ca/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 230w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 460w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 920w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/fbb05/FTP_Server_for_your_WQHD_Camera_using_Docker_03.webp 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5ce18cb96d502a60eb85b045ab29a7ca/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 230w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 460w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 920w", "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/2e367/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png 1066w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5ce18cb96d502a60eb85b045ab29a7ca/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_03.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wqhd-cameras",
        "aria-label": "wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WQHD Cameras`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d5301675eb7a1745631edd33e62941ba/aa61c/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "55.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACFElEQVQoz02RS08TURiGT104AxIJl2LboZd0ekbmQhONYKfO6Zw5px1q25k6t9KBXhagRiILFWKxUQOJienKlfFPCK5d+NtMLwHePNsn35f3Bfl8XlEUZTO/kcuQ3ucXF38bZ78bwz/m4NL8OGZncGUOrmpnl3ujfygcGKgoK5upVIrneSBNIssK5LMVp7//bhS8OW8dXQRH51Pc11/dV1+aB8P28Tds7WXS60vLyyzDLN1fBPIkiixDQSC6Fnq209gZY82guloqPtEKjw3tKXq2nUgm5ufnAADsXWYsTy7LEEJCabfX322HrudP8TyfEKqqxYKq6tjQsZ7kog9WFgEAcwwLpqYkSWOZkDAMPc9zb8U0TV3XEdJouWxgjU9Hk/HVO5EIy7BAFMWpLwgCIaTT7fq+f1vGGKuqWigUECoRA23w8WRsJRKJzC4rinIth2EYBMFt2ZqkXq/bdtMwStksF4tFZ2+LojjtDEJIKel3O2G7dfO459ctu1p9XjXNRq1Oif5QSN9bWBjLLDubSpIkAUJsEMvvVBq+1XRcx3Ec12nWyiUVIfUR0rYNbBA9OmkrAgDLMDdT8TlYM8nPU/fXB/uw47l+K/Dd3Zen7ZMfB4cn35vOKGibGK+urXEJLpGIr3PcTJZlCQoCRuqnPh12dadGDVopU2I6vUrnvW3vv0WlY1Iubm2lM5lcLsfzfJbn/wPwF59p5TbjawAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5301675eb7a1745631edd33e62941ba/e4706/FTP_Server_for_your_WQHD_Camera_using_Docker_04.avif 230w", "/en/static/d5301675eb7a1745631edd33e62941ba/d1af7/FTP_Server_for_your_WQHD_Camera_using_Docker_04.avif 460w", "/en/static/d5301675eb7a1745631edd33e62941ba/7f308/FTP_Server_for_your_WQHD_Camera_using_Docker_04.avif 920w", "/en/static/d5301675eb7a1745631edd33e62941ba/2cb71/FTP_Server_for_your_WQHD_Camera_using_Docker_04.avif 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d5301675eb7a1745631edd33e62941ba/a0b58/FTP_Server_for_your_WQHD_Camera_using_Docker_04.webp 230w", "/en/static/d5301675eb7a1745631edd33e62941ba/bc10c/FTP_Server_for_your_WQHD_Camera_using_Docker_04.webp 460w", "/en/static/d5301675eb7a1745631edd33e62941ba/966d8/FTP_Server_for_your_WQHD_Camera_using_Docker_04.webp 920w", "/en/static/d5301675eb7a1745631edd33e62941ba/fa9eb/FTP_Server_for_your_WQHD_Camera_using_Docker_04.webp 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d5301675eb7a1745631edd33e62941ba/81c8e/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png 230w", "/en/static/d5301675eb7a1745631edd33e62941ba/08a84/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png 460w", "/en/static/d5301675eb7a1745631edd33e62941ba/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png 920w", "/en/static/d5301675eb7a1745631edd33e62941ba/aa61c/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png 1065w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d5301675eb7a1745631edd33e62941ba/c0255/FTP_Server_for_your_WQHD_Camera_using_Docker_04.png",
              "alt": "Set up an FTP Server for your WQHD Camera using Docker",
              "title": "Set up an FTP Server for your WQHD Camera using Docker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      